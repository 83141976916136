var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"_base-count-down"},[_vm._t("default",null,null,{
      d: _vm.days,
      h: _vm.hours,
      m: _vm.mins,
      s: _vm.seconds,
      hh: ("00" + _vm.hours).slice(-2),
      mm: ("00" + _vm.mins).slice(-2),
      ss: ("00" + _vm.seconds).slice(-2),
    })],2)}
var staticRenderFns = []

export { render, staticRenderFns }