<template>
  <div class="details">
    <!-- <div class="bigHeader">
      <span style="font-size: 18px; margin-top: 100px">
        {{ auctionData.auctionTitle }}
      </span>
    </div> -->
    <div class="toDay">
      <div class="content">
        <div class="img">
          <SwiperVueSlide :imgLIst="auctionData.objectNames" />
        </div>
        <div class="contentDetail">
          <div class="title">
            <span
              :class="{
                status: auctionData.auctionStatus != '0',
                status1: auctionData.auctionStatus == '0',
              }"
              >{{ auctionData.auctionStatus | numToStr }}</span
            >
            <!-- auctionStatus -->
            <span class="titleStyle">
              {{ auctionData.auctionTitle }}
            </span>
          </div>
          <div class="pricesOn">
            <div class="item">
              <span class="wz">拍卖编号</span>
              <div class="pub price1">{{ auctionData.auctionCode }}</div>
            </div>
            <div class="item">
              <span class="wz jjs">{{
                auctionData.auctionStatus == "0" ? "距开始" : "距结束"
              }}</span>
              <div class="pubTime" v-if="auctionData.auctionStatus == 0">
                <CountDown
                  v-slot="timeObj"
                  :end="endTime"
                  :isMilliSecond="false"
                >
                  <slot v-if="timeObj.d">
                    <span class="time"> {{ timeObj.d }}</span> 天 </slot
                  ><span class="time">{{ timeObj.hh }}</span> 小时
                  <span class="time">{{ timeObj.mm }}</span> 分钟
                  <span class="time"> {{ timeObj.ss }}</span> 秒
                </CountDown>
                <span class="text"
                  >（ {{ auctionData.dateDay }}
                  {{ auctionData.auctionStartTime }} 开始）</span
                >
              </div>
              <div class="pubTime" v-else>
                <CountDown
                  v-slot="timeObj"
                  :time="trueAuctionItemEndTime"
                  :isMilliSecond="false"
                >
                  <span class="time">{{ timeObj.hh }}</span> 小时
                  <span class="time">{{ timeObj.mm }}</span> 分钟
                  <span class="time"> {{ timeObj.ss }}</span> 秒
                </CountDown>
                <span class="text"
                  >（最晚 {{ auctionData.dateDay }}
                  {{ auctionData.auctionEndTime }} 结束）</span
                >
              </div>
            </div>
            <div
              style="
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                box-sizing: border-box;
                padding-right: 20px;
              "
            >
              <div class="itemRight item">
                <span class="wz jjs">起拍价</span>
                <div class="pub">
                  <span
                    v-if="
                      auctionData.auctionStatus == '0' &&
                      auctionData.isPreQuotation == 'Y'
                    "
                    style="font-size: 14px; color: #ff7f00"
                    >开拍前公布</span
                  >

                  <span v-else class="pubPrice">
                    {{ auctionData.startingPrice | formatMoneyString }}元
                    <span v-if="auctionData.quoting == 1"
                      >/ {{ auctionData.quotationUnit }}
                    </span>
                  </span>
                </div>
              </div>

              <div
                class="itemLeft item"
                v-if="
                  auctionData.auctionStatus == 0 ||
                  auctionData.auctionStatus == 1
                "
                v-show="smsType == '0'"
                @click="dandReminder"
              >
                <img
                  src="@/assets/images/bidProject/nz.png"
                  style="width: 24px; margin: 0 3px 3px 0"
                  alt=""
                />
                <span>设置提醒</span>
              </div>
              <div
                class="itemLeft item"
                v-show="smsType != '0'"
                @click="noReminder"
                v-if="
                  auctionData.auctionStatus == 0 ||
                  auctionData.auctionStatus == 1
                "
              >
                <img
                  src="@/assets/images/bidProject/qxtx.png"
                  style="width: 24px; margin: 0 3px 3px 0"
                  alt=""
                />
                <span>取消提醒</span>
              </div>
            </div>
          </div>
          <div class="prices">
            <div class="left">
              <div class="item">
                <div class="wz jjs">保证金</div>
                <div class="pub">
                  {{ auctionData.deposit | formatMoneyString }} 元
                </div>
              </div>
              <div class="item">
                <div class="wz jjs">起拍价</div>
                <div class="price">
                  <span
                    v-if="
                      auctionData.auctionStatus == '0' &&
                      auctionData.isPreQuotation == 'Y'
                    "
                    style="font-size: 14px"
                    >开拍前公布</span
                  >
                  <span v-else class="pubPrice">
                    {{ auctionData.startingPrice | formatMoneyString }}元
                    <span v-if="auctionData.quoting == 1"
                      >/ {{ auctionData.quotationUnit }}
                    </span>
                  </span>
                </div>
              </div>

              <div class="item">
                <div class="wz">竞价周期</div>
                <div class="price price1">
                  <span>{{ auctionData.freeBiddingStage }}分钟</span>
                </div>
              </div>
              <div class="item">
                <div class="wz">交易数量</div>
                <div class="price price1">
                  <span
                    >{{
                      auctionData.quoting == 1
                        ? auctionData.transactionQuantity
                        : "--"
                    }}{{ auctionData.quotationUnit }}</span
                  >
                </div>
              </div>
            </div>
            <div class="right">
              <div class="item">
                <div class="wz jjs">评估价</div>
                <div
                  v-if="
                    auctionData.auctionStatus == '0' &&
                    auctionData.isPreQuotation == 'Y'
                  "
                  style="font-size: 14px"
                >
                  开拍前公布
                </div>
                <div class="pub" v-else>
                  {{ auctionData.marketValuation | formatMoneyString }} 元
                  <span
                    v-if="
                      auctionData.quoting == 1 && auctionData.marketValuation
                    "
                    >/ {{ auctionData.quotationUnit }}
                  </span>
                </div>
              </div>
              <div class="item">
                <div class="wz jjs">加价幅</div>
                <div
                  v-if="
                    auctionData.auctionStatus == '0' &&
                    auctionData.isPreQuotation == 'Y'
                  "
                  style="font-size: 14px"
                >
                  开拍前公布
                </div>

                <div v-else class="price">
                  {{ auctionData.minimumMarkup | formatMoneyString }}元
                  <span v-if="auctionData.quoting == 1"
                    >/ {{ auctionData.quotationUnit }}
                  </span>
                </div>
              </div>
              <div class="item">
                <div class="wz">延时周期</div>
                <div class="pub price1">{{ auctionData.delayLength }}分钟</div>
              </div>
            </div>
          </div>

          <div
            class="preQuotationStyle"
            v-if="!bmBtnVisible && auctionData.isPreQuotation == 'Y'"
          >
            <span>我的预报价：</span>
            <span>{{ auctionData.preQuotation | formatMoneyString }}</span>
          </div>

          <!-- 竞拍未开始和正在拍卖展示 -->
          <div
            class="bot"
            v-if="
              auctionData.auctionStatus == '0' ||
              auctionData.auctionStatus == '1'
            "
          >
            <div class="btnStyle">
              <el-button
                v-if="bmBtnVisible"
                size="medium"
                type="success"
                @click="toApplication()"
                >立即报名</el-button
              >
              <el-button
                v-else
                size="medium"
                type="success"
                @click="toBidHall()"
                >进入竞拍大厅</el-button
              >
            </div>

            <div class="botText">
              <div class="tit">竞拍提醒</div>
              <div class="abcd">
                <em class="abcdBefore" />
                请先完成报名再进行出价。如已经报名，请登录查看工作台。
              </div>
              <div class="abcd">
                <em class="abcdBefore" />
                本场拍卖仅接受企业参与，请确保您代表企业，目企业已通过签章授权，以便于成功竞标后签署交易合同。
              </div>
              <div class="abcd" v-if="auctionData.isReservePrice == 'Y'">
                <em class="abcdBefore" />
                本场设置保留价，拍卖最高(最终)出价高于保留价方可成交。
              </div>
            </div>
          </div>

          <!-- 成交，已支付，合同签署完成 -->
          <div
            class="bot"
            v-if="
              auctionData.auctionStatus == '2' ||
              auctionData.auctionStatus == '6' ||
              auctionData.auctionStatus == '7'
            "
          >
            <div class="success">拍卖已成交</div>
          </div>

          <!-- 流拍 -->
          <div class="bot" v-if="auctionData.auctionStatus == '3'">
            <div class="success">拍卖已流拍</div>
          </div>

          <!-- 取消 -->
          <div class="bot" v-if="auctionData.auctionStatus == '4'">
            <div class="success">
              <div class="cancel">拍卖已取消</div>
              <!-- <div class="cancelReason">
                取消原因：因出卖方标的发生不可抗力导致无法售卖。
              </div> -->
            </div>
          </div>

          <!-- 中止 -->
          <div class="bot" v-if="auctionData.auctionStatus == '5'">
            <div class="cancel">拍卖已中止</div>
            <!-- <div class="cancelReason">
              中止原因：因出卖方标的发生不可抗力导致无法售卖。
            </div> -->
          </div>

          <div class="foot">
            <em class="el-icon-warning-outline"></em>
            <span>成交后，需另支付综合服务费（具体金额以拍卖公告为准）</span>
            <!-- <div class="link">
              <span> 查看详情</span>
              <em class="el-icon-arrow-right"></em>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="listBox">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="标的介绍" name="first">
          <div class="bdjs" v-html="auctionData.introduction"></div>
        </el-tab-pane>
        <el-tab-pane label="竞买公告" name="second">
          <Bulletin />
        </el-tab-pane>
        <!-- <el-tab-pane label="竞买须知" name="third">
          <Notice />
        </el-tab-pane> -->
        <el-tab-pane label="竞买记录" name="fourth">
          <el-table
            :data="bidRecordData"
            style="width: 100%"
            v-loading="loading"
            :header-cell-style="{
              background: '#F5F5F5',
              fontWeight: 600,
              fontSize: '14px',
              color: '#000000d9',
            }"
          >
            <el-table-column
              align="center"
              show-overflow-tooltip
              prop="bidStatus"
              label="出价状态"
              width="300"
            >
            </el-table-column>
            <el-table-column
              align="center"
              show-overflow-tooltip
              prop="bidNum"
              label="竞买号"
              width="300"
            >
            </el-table-column>
            <el-table-column
              align="center"
              show-overflow-tooltip
              prop="bidPrice"
              label="出价金额"
              width="300"
            >
            </el-table-column>
            <el-table-column
              align="center"
              show-overflow-tooltip
              prop="bidTime"
              label="出价时间"
            >
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog title="设置提醒" :visible.sync="dialogVisible" width="700px">
      <div class="tixingBox">
        <div class="title">提醒时间</div>
        <div class="selects">
          <div class="left" v-if="auctionData.auctionStatus != 1">
            <div class="item">
              <span>开始时</span>
              <el-switch v-model="reminderFrom.kaiguan1"> </el-switch>
            </div>
            <div class="item">
              <span class="itemTit"> {{ auctionData.dateDay }}</span>
              <span class="titleNum"> {{ auctionData.auctionStartTime }} </span>
            </div>
          </div>
          <div class="right">
            <div class="item">
              <span>延时竞拍开始前10分钟</span>
              <el-switch v-model="reminderFrom.kaiguan2"> </el-switch>
            </div>
            <div class="item">
              <span class="itemTit"> {{ auctionData.dateDay }}</span>
              <span class="titleNum">
                {{ num_10 }}
              </span>
            </div>
          </div>
        </div>
        <div class="title">提醒方式</div>
        <div style="width: 100%; box-sizing: border-box; padding: 10px 30px">
          <p style="display: flex; align-items: center; margin-bottom: 10px">
            <img src="@/assets/images/bidProject/dxtx.png" alt="" />
            <span class="itemTit" style="margin-left: 6px">手机短信提醒</span>
          </p>
          <div class="phoneBox">
            <span class="titleNum">{{ phone }}</span>
            <span class="itemTit">（提醒登录手机号，暂不支持修改）</span>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitTX">确 定</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>

    <!-- 报名弹窗 -->
    <el-dialog
      title="竞拍报名"
      :visible.sync="bmVisible"
      width="960px"
      :close-on-click-modal="false"
    >
      <div class="confirmTip">
        <div class="title">竞买人请确认以下事项并勾选</div>
        <ul class="ulBox">
          <li class="liPub">
            <span class="circle"></span
            >已阅读并知晓：拍下不买的后果，即保证金不予退还并承担相应的后果包括处罚等
          </li>
          <li class="liPub">
            <span class="circle"></span
            >已阅读并知晓：本次报名仅代表所属企业委托参与竞买
          </li>
          <li class="liPub1">
            <span class="circle"></span
            >已阅读并知晓：如您报名成功，您的个人和企业信息可能披露给资产处置单位及相关服务提供方，用于核实竞买人的真实身份、竞买资格及相关服务提供
          </li>
          <li class="liPub">
            <span class="circle"></span
            >已阅读并知晓：竞买人如果没有进行实地看样，视为接受标的所有瑕疵等细则
          </li>
          <li class="liPub">
            <span class="circle"></span>
            已阅读并同意
            <span class="pdf" @click="viewOperationRule()"
              >《保证金操作细则》</span
            >和
            <span class="pdf" @click="viewFeeRule()"
              >《平台服务费收费规则》</span
            >
          </li>
        </ul>
        <el-checkbox v-model="bmChecked"
          ><span></span>本人已知晓并同意以上所有事项</el-checkbox
        >
      </div>

      <div class="enterpriseConfirm">
        <div class="title">竞买人身份及企业信息确认</div>
        <div class="enterpriseBox">
          <div class="personName">
            <div>代理人姓名</div>
            <div>{{ userInfo.realName }}</div>
          </div>

          <div class="personTel">
            <div>代理人电话</div>
            <div>{{ userInfo.userName }}（登录账号）</div>
          </div>

          <div class="personEnterPrise">
            <div>竞买企业</div>
            <div>{{ userInfo.manuName }}</div>
          </div>

          <div class="personEnterPriseCode">
            <div>竞买企业统一社会信用代码</div>
            <div>{{ userInfo.manufactoryCode }}</div>
          </div>

          <div class="areaList">
            <div>竞买企业所在地区</div>
            <div>
              {{ auctionData.manufactoryNameAdress }}
            </div>
          </div>
        </div>
      </div>

      <div class="preQuotationConfirm" v-if="signUpData.isPreQuotation == 'Y'">
        <div class="title">本场拍卖要求用户报名前提交针对本标的的预报价</div>
        <div class="preQuotationBox">
          <div class="redStar">*</div>
          <span>请填写贵企预报价：</span>
          <div class="inputStyle">
            <el-input
              size="small"
              v-model="preQuotation"
              placeholder="请输入您的预报价"
              @blur="validateInput"
            >
              <template slot="prepend">￥</template>
            </el-input>
            <div v-if="errorMessage" style="color: red; font-size: 12px">
              {{ errorMessage }}
            </div>
          </div>
        </div>
      </div>

      <div class="payBox">
        <div class="title">支付金额</div>

        <div class="subjectMatter">
          <div>标的物名称</div>
          <div>{{ auctionData.auctionTitle }}</div>
        </div>

        <div class="payPrice">
          <div>需缴纳保证金</div>
          <div>{{ formatMoneyString(auctionData.deposit) }}</div>
        </div>
      </div>

      <div class="paySum">
        <div>共需支付金额</div>
        <div>
          <span>{{ formatMoneyString(auctionData.deposit) }}</span>
        </div>
      </div>
      <div class="dialog-footer">
        <el-button type="success" @click="toPay">立即支付</el-button>
      </div>
    </el-dialog>

    <!-- 支付弹窗 -->
    <el-dialog
      title="企业钱包"
      :visible.sync="bmPayVisible"
      width="480px"
      :close-on-click-modal="false"
    >
      <div class="payPriceBox">
        <div class="priceBox">
          <div class="title">支付金额</div>
          <div class="price">
            <span>{{ formatMoneyString(auctionData.deposit) }}</span>
          </div>
        </div>

        <div class="amountAccounting">
          <div class="title">金额核算</div>

          <div class="deposit">
            <div>竞拍保证金</div>
            <div>{{ formatMoneyString(auctionData.deposit) }}</div>
          </div>

          <div class="realPay">
            <div>实际支付</div>
            <div>{{ formatMoneyString(auctionData.deposit) }}</div>
          </div>
        </div>

        <div class="payPassword">
          <div class="payAccount">
            <div>支付密码</div>
            <div>支付账户：{{ userInfo.manuName }}-企业钱包</div>
          </div>
          <div class="balance">
            可用余额：{{ formatMoneyString(withdrawalForm.availableBalance) }}
          </div>
        </div>

        <div class="password">
          <PasswordInput
            ref="passwordInputRef"
            v-model="withdrawalForm.paymentPassword"
            has-gap
            @complete="onPasswordInputComplete"
          />
        </div>
      </div>
    </el-dialog>

    <!-- 支付成功弹窗 -->
    <el-dialog
      title="恭喜报名成功"
      :visible.sync="bmPaySuccessVisible"
      width="480px"
      :close-on-click-modal="false"
    >
      <div class="paySuccessBox">
        <div class="payEnterprise">
          <div>参拍企业</div>
          <div>{{ userInfo.manuName }}</div>
        </div>

        <div class="personName">
          <div>代理人</div>
          <div>{{ userInfo.realName }}</div>
        </div>

        <div class="bidNumber">
          <div>出价号</div>
          <div>{{ bidNumber }}</div>
        </div>

        <div class="paySuccessBtn">
          <el-button type="success" @click="bmPaySuccessVisible = false">
            确认
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import SwiperVueSlide from "./component/swiperVueSlide.vue";
import Bulletin from "./component/bulletin.vue";
// import Notice from "./component/Notice.vue";
import CountDown from "./component/countDown"; //引入路径，可更改
import { mapState } from "vuex";
import PasswordInput from "@/pages/AccountSettings/components/PasswordInput.vue";
import {
  getDetail,
  reminder,
  cancelReminder,
  depositPayment,
  haveAuthentication,
} from "@/api/bidProject";
import { queryBidRecord } from "@/api/bidHall";
import { getInfo } from "@/api/index";
import { balance } from "@/api/account";
import { encrypt, decrypt } from "@/utils/jsencrypt.js";
export default {
  name: "Project",
  components: {
    SwiperVueSlide,
    CountDown,
    Bulletin,
    PasswordInput,
    // Notice,
  },
  data() {
    return {
      loading: false,
      bidRecordData: [],
      bidNumber: "",
      application: "0", //报名状态
      withdrawalForm: {},
      bmChecked: false, //报名弹窗知晓事项多选按钮
      bmVisible: false, //报名弹窗
      bmPayVisible: false, //报名支付页面
      bmPaySuccessVisible: false, //支付成功弹窗
      bmBtnVisible: true,
      auctionData: {
        fileList: [],
      },
      signUpData: {}, //报名获取的详情
      preQuotation: "", //企业预报价
      errorMessage: "", //预报价填写错误信息
      endTime: "",
      activeName: "second",
      dialogVisible: false,
      reminderFrom: {
        kaiguan1: false,
        kaiguan2: false,
      },
      phone: "",
      smsType: "0",
      trueAuctionItemEndTime: 0,
    };
  },
  watch: {
    bmPayVisible(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs["passwordInputRef"].focus();
        });
      }
    },
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
      areaList: (state) => state.area.areaList,
    }),
    num_10() {
      if (this.auctionData.auctionStartTime) {
        const minutesToAdd = this.auctionData.freeBiddingStage;
        const [hours, minutes] = this.auctionData.auctionStartTime.split(":");
        const date = new Date();
        date.setHours(parseInt(hours));
        date.setMinutes(parseInt(minutes));
        date.setMinutes(date.getMinutes() + minutesToAdd - 10);
        const updatedHours = date.getHours().toString().padStart(2, "0");
        const updatedMinutes = date.getMinutes().toString().padStart(2, "0");
        return `${updatedHours}:${updatedMinutes}`;
      }
    },
  },
  mounted() {
    if (this.$route.query) {
      this.getData();
      // this.getRecordData();
    }
  },
  filters: {
    // 金额添加千分符
    formatMoneyString(moneyString) {
      if (!moneyString) {
        return "--";
      }
      // 将金额字符串转换为数字
      const amount = parseFloat(moneyString);
      // 检查是否成功转换为数字
      if (isNaN(amount)) {
        return "--";
      }
      // 使用 toLocaleString() 方法添加千分符并保留两位小数
      const formattedAmount = amount.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return formattedAmount;
    },
    numToStr(num) {
      let obj = {
        0: "即将开始",
        1: "正在拍卖",
        2: "已成交",
        6: "已成交",
        7: "已成交",
        3: "拍卖已流拍",
        4: "拍卖已取消",
        5: "拍卖已中止",
      };
      return obj[num];
    },
  },
  methods: {
    viewOperationRule() {
      // 查看操作细则
      window.open(
        "/api/file/displayResource?objectName=/paymentInstructions/保证金操作细则.pdf"
      );
    },
    viewFeeRule() {
      // 平台服务费收费规则
      window.open(
        "/api/file/displayResource?objectName=/paymentInstructions/平台服务费收费规则.pdf"
      );
    },
    handleClick(tab, event) {
      if (tab.name == "fourth") {
        this.getRecordData();
      }
    },
    // 竞买记录
    getRecordData() {
      this.loading = true;
      queryBidRecord({ auctionCode: this.$route.query.auctionCode }).then(
        (res) => {
          if (res.code == 200) {
            this.bidRecordData = res.data.map((item, index) => {
              if (index == "0") {
                item.bidStatus = "领先";
              } else {
                item.bidStatus = "出局";
              }
              return item;
            });
            this.loading = false;
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        }
      );
    },

    // 立即报名弹窗input框添加校验
    validateInput() {
      // 定义正则表达式：允许整数或最多两位小数
      const regex = /^\d+(\.\d{1,2})?$/;

      if (!this.preQuotation) {
        this.errorMessage = "预报价不能为空";
      } else if (!regex.test(this.preQuotation)) {
        this.errorMessage = "预报价必须为整数，并且最多两位小数";
      } else {
        this.errorMessage = "";
      }
    },

    getData() {
      let params = {
        auctionCode: this.$route.query.auctionCode,
        userName: this.userInfo.userName,
      };
      getDetail(params).then(({ data, code }) => {
        if (code == 200) {
          this.trueAuctionItemEndTime = data.auctionItemEndTime / 1000;
          this.auctionData = data.auctionItemAndConfigDTO;
          this.auctionData.preQuotation = data.preQuotation;
          this.auctionData.manufactoryNameAdress = data.manufactoryNameAdress;
          let arr = [];
          this.auctionData.accessoryName.forEach((item) => {
            const parts = item.split("/");
            const fileName = parts[parts.length - 1];
            arr.push({
              fileName: fileName,
              url: item,
            });
          });
          this.auctionData.fileList = arr;
          this.application = data.application; //报名状态
          // 如果没有登录，直接显示立即登录按钮
          if (!this.userInfo.userName) {
            this.bmBtnVisible = true;
          } else {
            if (this.userInfo.manuId) {
              // application  报名状态    0=未报名  1=同企业非本人报名  2=本人报名 3=发起方
              if (this.application == "0" || this.application == "3") {
                this.bmBtnVisible = true;
              } else {
                this.bmBtnVisible = false;
              }
            } else {
              this.bmBtnVisible = true;
            }
          }
          this.smsType = data.smsType;
          if (data.smsType == "1") {
            this.reminderFrom.kaiguan1 = true;
            this.reminderFrom.kaiguan2 = false;
          } else if (data.smsType == "2") {
            this.reminderFrom.kaiguan1 = false;
            this.reminderFrom.kaiguan2 = true;
          } else if (data.smsType == "3") {
            this.reminderFrom.kaiguan1 = true;
            this.reminderFrom.kaiguan2 = true;
          } else {
            this.reminderFrom.kaiguan1 = false;
            this.reminderFrom.kaiguan2 = false;
          }

          if (this.auctionData.dateDay && this.auctionData.auctionStartTime) {
            this.endTime =
              new Date(
                this.auctionData.dateDay +
                  " " +
                  this.auctionData.auctionStartTime
              ).getTime() /
                1000 +
              " ";
          }
        }
      });
    },
    dandReminder() {
      // 未登录不可设置提醒，弹出登录框
      if (!this.userInfo.userName) {
        this.$store.commit("SETLOGIN", true);
      } else {
        this.dialogVisible = true;
        this.phone = this.userInfo.userName;
      }
    },
    submitTX() {
      let param = {
        smsType: "0",
        auctionCode: this.auctionData.auctionCode,
      };
      if (
        this.reminderFrom.kaiguan1 == true &&
        this.reminderFrom.kaiguan2 == false
      ) {
        param.smsType = "1";
      } else if (
        this.reminderFrom.kaiguan1 == false &&
        this.reminderFrom.kaiguan2 == true
      ) {
        param.smsType = "2";
      } else if (
        this.reminderFrom.kaiguan1 == true &&
        this.reminderFrom.kaiguan2 == true
      ) {
        param.smsType = "3";
      } else {
        param.smsType = "0";
      }
      reminder(param).then(({ code, msg }) => {
        if (code == 200) {
          this.dialogVisible = false;
          this.$message.success(msg);
          this.getData();
        } else {
          this.$message.error(msg);
        }
      });
    },
    noReminder() {
      this.$confirm("确认取消开拍前短信提醒?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        cancelReminder({
          auctionCode: this.auctionData.auctionCode,
        }).then(({ code, msg }) => {
          if (code == 200) {
            this.$message.success(msg);
            this.getData();
          } else {
            this.$message.error(msg);
          }
        });
      });
    },

    // 进入竞拍大厅
    toBidHall() {
      let params = {
        auctionCode: this.$route.query.auctionCode,
        userName: this.userInfo.userName,
      };
      getDetail(params).then(({ data, code, msg }) => {
        if (code == 200) {
          if (
            data.auctionItemAndConfigDTO.auctionStatus == "0" ||
            data.auctionItemAndConfigDTO.auctionStatus == "1"
          ) {
            this.$router.push({
              path: "/bidHall",
              query: {
                // Vue Router 的 query 参数只能是简单的字符串格式。如果你将对象作为 query 参数传递，它会被序列化成字符串，导致你在页面刷新时获取的 params 变成了 "[object Object]"。
                params: JSON.stringify({
                  auctionCode: this.auctionData.auctionCode,
                  application: this.application,
                  startingPrice: data.auctionItemAndConfigDTO.startingPrice,
                }),
              },
            });
          } else {
            this.$message({
              type: "warning",
              message: "当前竞拍已结束，请刷新页面！",
            });
          }
        }
      });
    },

    // 立即报名
    toApplication() {
      // 立即报名按钮首先判断是否登录，没登录弹登录弹窗
      if (!this.userInfo.userName) {
        // 没登录弹登录弹窗
        this.$store.commit("SETLOGIN", true);
      } else {
        // 登录了，判断当前登录账户有没有加入企业
        if (!this.userInfo.manuId) {
          // 如果没有manuId代表没有加入企业
          this.$confirm(
            "您好，您暂时无法进行竞拍报名。请确认您已通过实名认证，并加入或创建企业。本场竞拍仅接受企业名义报名参拍，感谢您的支持。",
            "提示",
            {
              confirmButtonText: "前往确认",
              cancelButtonText: "取消",
              type: "warning",
            }
          ).then(() => {
            this.$router.push({
              path: "/AssociatedEnterprise",
            });
          });
        } else {
          if (this.application == "3") {
            this.$message({
              type: "warning",
              message: "卖方企业成员不可报名竞拍！",
            });
          } else {
            getInfo().then((res) => {
              if (res.code == 200) {
                if (res.user.walletStatus == "2") {
                  this.$message({
                    type: "warning",
                    message: "您的账户已冻结，请联系管理员操作！",
                  });
                } else {
                  // 判断当前项目状态，结束给他提示
                  let params = {
                    auctionCode: this.$route.query.auctionCode,
                    userName: this.userInfo.userName,
                  };
                  getDetail(params).then(({ data, code, msg }) => {
                    if (code == 200) {
                      if (
                        data.auctionItemAndConfigDTO.auctionStatus == "0" ||
                        data.auctionItemAndConfigDTO.auctionStatus == "1"
                      ) {
                        // 判断该项目是否需要废危品经营许可证
                        if (data.auctionItemAndConfigDTO.isQualify == "Y") {
                          // 需要资质
                          haveAuthentication({
                            authenticationName: "废危品经营许可证",
                          }).then((res) => {
                            if (res.msg == "1") {
                              // 该企业有资格许可证
                              this.bmVisible = true;
                              this.signUpData = data.auctionItemAndConfigDTO;
                            } else {
                              // 2  代表没有资格许可证
                              this.$confirm(
                                "您的企业暂未通过“废危品经营许可证”的认证，请您完成认证后再进行报名。若非企业管理员请联系管理员完成认证。",
                                "提示",
                                {
                                  showConfirmButton:
                                    this.userInfo.roles[0].roleId == "4",
                                  confirmButtonText: "前往确认",
                                  cancelButtonText: "取消",
                                  type: "warning",
                                }
                              ).then(() => {
                                this.$router.push({
                                  path: "/EnterpriseQualification",
                                });
                              });
                            }
                          });
                        } else {
                          // 不需要资质
                          this.bmVisible = true;
                          this.signUpData = data.auctionItemAndConfigDTO;
                        }
                      } else {
                        this.$message({
                          type: "warning",
                          message: "当前竞拍已结束，请刷新页面！",
                        });
                      }
                    }
                  });
                }
              } else {
                this.$message({
                  type: "error",
                  message: res.msg,
                });
              }
            });
          }
        }
      }
    },

    // 立即支付
    toPay() {
      if (this.bmChecked) {
        if (this.signUpData.isPreQuotation == "Y") {
          this.validateInput(); // 先调用校验方法
          if (this.errorMessage) {
            // 如果有错误信息，提示用户并阻止支付
            return; // 阻止后续支付操作
          } else {
            this.bmVisible = false;
            this.bmPayVisible = true;
            balance().then(({ code, data }) => {
              if (code == 200) this.withdrawalForm = data;
            });
          }
        } else {
          this.bmVisible = false;
          this.bmPayVisible = true;
          balance().then(({ code, data }) => {
            if (code == 200) this.withdrawalForm = data;
          });
        }
      } else {
        this.$message({
          message:
            "请仔细阅读保证金操作细则和平台服务费收费规则并勾选同意以上所有事项",
          type: "warning",
        });
      }
    },

    // 密码输入完成回调
    onPasswordInputComplete(val) {
      let pwd = encrypt(val);
      let param = {
        authCode: this.auctionData.auctionCode,
        amount: this.auctionData.deposit,
        pwd,
        preQuotation: this.preQuotation,
      };
      depositPayment(param).then((res) => {
        if (res.code == 200) {
          this.bmPayVisible = false;
          this.bmPaySuccessVisible = true;
          this.bidNumber = res.msg;
          this.getData();
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },

    // 下载
    // downloadFile(url, fileName) {
    //   this.download("/file/downloadFile", { objectName: url }, `${fileName}`);
    // },
  },
};
</script>

<style lang="less" scoped>
.el-button--success {
  background-color: #0dac65;
}

.el-button--success.is-plain {
  background-color: #fff;
  border-color: #0dac65;
  color: #0dac65;
}
.pub {
  font-weight: 400;
  font-size: 14px;
  color: #000000cc;
}
.price1 {
  margin-left: 6px;
}
.details {
  width: 100%;
  min-height: calc(100vh - 450px);
  .bigHeader {
    width: 100%;
    height: 160px;
    background: url("../../assets/images/bidProject/detailsHader.png");
    background-size: cover;
    color: #fff;
    padding-top: 100px;
    box-sizing: border-box;
    margin-bottom: 20px;
  }
  .toDay {
    width: 1200px;
    margin: 0 auto;
    margin-top: 120px;

    .content {
      width: 100%;
      background: #ffffff;
      // box-shadow: 0 3px 6px 1px #0000000f;
      display: flex;
      justify-content: space-between;
      padding: 16px 0;
      .img {
        width: 680px;
        height: 100%;
      }
      .contentDetail {
        width: 500px;
        height: 100%;
        box-sizing: border-box;

        .title {
          width: 100%;
          height: 72px;
          // padding: 0 20px;
          box-sizing: border-box;
          position: relative;
          .status {
            position: absolute;
            width: 84px;
            height: 24px;
            font-weight: 600;
            font-size: 14px;
            color: #ff7f00;
            letter-spacing: 0;
            text-align: center;
            line-height: 24px;
            // padding: 6px;
            background: rgba(255, 127, 0, 0.1);
            box-sizing: border-box;
          }
          .status1 {
            position: absolute;
            top: 4px;
            width: 84px;
            height: 24px;
            background-color: rgba(13, 172, 101, 0.1);
            font-weight: 600;
            font-size: 14px;
            color: #0dac65;
            text-align: center;
            line-height: 24px;
            // padding: 6px;
            box-sizing: border-box;
            margin-right: 10px; /* 可以调整间距 */
          }
          .titleStyle::before {
            content: "";
            display: inline-block;
            width: 94px;
            height: 1px;
            position: static;
          }
          .titleStyle {
            line-height: 1.5; /* 调整行高，可以根据需要调整这个值 */
            // white-space: nowrap; /* 不换行 */
            overflow: hidden; /* 隐藏超出部分 */
            text-overflow: ellipsis; /* 用省略号表示超出部分 */
            max-width: 100%; /* 限制宽度不超过父元素宽度 */
            display: -webkit-box;
            -webkit-line-clamp: 2; /* 显示的行数 */
            -webkit-box-orient: vertical;
            font-weight: 500;
            font-size: 22px;
            color: #000000d9;
          }
        }
        .jjs {
          letter-spacing: 6px;
        }
        .pricesOn {
          margin-bottom: 10px;
          .item {
            display: flex;
            align-items: center;
            height: 34px;
            line-height: 34px;
            .wz {
              font-size: 14px;
              color: #00000066;
              margin-right: 20px;
            }
            .pubTime {
              display: flex;
              align-items: center;
              color: #999;
              .time {
                font-size: 20px;
                font-weight: 600;
                color: #333;
                // margin-left: 6px;
              }
              .text {
                margin-left: 20px;
                font-size: 14px;
                color: #00000066;
              }
            }
            .pubPrice {
              font-size: 22px;
              font-weight: 600;
              color: #ff7f00;
              .sma {
                font-size: 14px;
              }
            }
          }
          .itemLeft {
            cursor: pointer;
            color: #999;
          }
          .itemLeft:hover {
            color: #000;
          }
        }

        .prices {
          width: 100%;
          box-sizing: border-box;
          padding-top: 10px;
          border-top: 1px solid #ccc;
          display: flex;

          .left {
            width: 40%;
          }
          .right {
            width: 50%;
          }
          .wz {
            font-size: 14px;
            color: #00000066;
            margin-right: 20px;
          }
          .item {
            display: flex;
            align-items: center;
            line-height: 28px;
            .price {
              font-size: 14px;
            }
          }
        }
        .preQuotationStyle {
          height: 28px;
          line-height: 28px;
          span {
            font-size: 14px;
            &:first-child {
              color: #00000066;
            }
            &:last-child {
              color: #ff7f00;
            }
          }
        }

        .bot {
          width: 100%;
          box-sizing: border-box;
          padding: 6px 38px 8px 20px;
          background-color: #f5f5f5;
          margin-top: 10px;
          display: flex;
          align-items: center;
          .btnStyle {
            width: 130px;
            .preQuotationStyle {
            }
          }
          .botText {
            padding-left: 30px;
            color: #666;
            .tit {
              font-size: 14px;
              color: #000;
              font-weight: 600;
              line-height: 30px;
            }
            .abcd {
              line-height: 20px;
              position: relative;
              .abcdBefore {
                position: absolute;
                background: #0dac65;
                width: 6px;
                height: 6px;
                border-radius: 3px;
                top: 6px;
                left: -10px;
              }
            }
          }

          .success {
            height: 100px;
            width: 100%;
            font-weight: 500;
            font-size: 32px;
            color: #00000040;
            text-align: center;
            line-height: 100px;
          }
          .cancel {
            width: 100%;
            height: 100px;
            line-height: 100px;
            font-weight: 500;
            font-size: 32px;
            color: #00000040;
            text-align: center;
          }
          .cancelReason {
            font-weight: 400;
            font-size: 14px;
            color: #00000099;
            text-align: center;
          }
        }
        .foot {
          display: flex;
          align-items: center;
          font-size: 12px;
          color: #ff7f00;
          letter-spacing: 0;
          text-align: center;
          line-height: 24px;
          padding: 6px 20px;
          background: rgba(255, 127, 0, 0.1);
          margin: 10px 0;
          .link {
            cursor: pointer;
            display: flex;
            align-items: center;
            margin-left: 14px;
          }
          i {
            font-weight: 700;
            font-size: 14px;
            margin: 0 8px;
          }
        }
      }
    }
  }
  .listBox {
    width: 1200px;
    margin: 18px auto;
  }
  .tixingBox {
    width: 100%;

    .title {
      font-weight: 600;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
    }
    .titleNum {
      font-weight: 600;
      font-size: 22px;
      color: rgba(0, 0, 0, 0.85);
    }
    .itemTit {
      color: rgba(0, 0, 0, 0.6);
    }
    .selects {
      width: 100%;
      box-sizing: border-box;
      padding: 10px 30px;
      display: flex;
      .left {
        width: 49%;
        border-right: 1px solid #ccc;
        box-sizing: border-box;
        padding-right: 20px;
      }
      .right {
        box-sizing: border-box;
        width: 50%;
        padding-left: 20px;
      }

      .item {
        width: 100%;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}

.confirmTip {
  .title {
    font-weight: 600;
    font-size: 16px;
    color: #000000d9;
  }
  .ulBox {
    margin-top: 18px;
    .liPub,
    .liPub1 {
      font-weight: 400;
      font-size: 14px;
      color: #00000099;
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      .circle {
        display: block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        border: 1px solid #0dac65;
        margin-right: 10px;
      }
      .pdf {
        color: #0dac65;
        cursor: pointer;
      }
    }
    .liPub1 {
      align-items: flex-start;
      span {
        margin-top: 5px;
      }
    }
  }
}

.enterpriseConfirm,
.preQuotationConfirm {
  margin-top: 32px;
  .title {
    font-weight: 600;
    font-size: 16px;
    color: #000000d9;
  }
  .enterpriseBox {
    margin-top: 16px;
    .personName,
    .personTel,
    .personEnterPrise,
    .personEnterPriseCode,
    .areaList {
      display: flex;
      margin-bottom: 12px;
      padding-left: 20px;
      box-sizing: border-box;
      div {
        &:first-child {
          width: 200px;
          text-align: left;
          font-weight: 400;
          font-size: 14px;
          color: #00000099;
        }
        &:last-child {
          text-align: left;
          font-weight: 400;
          font-size: 14px;
          color: #000000d9;
        }
      }
    }
  }
}

.preQuotationConfirm {
  .preQuotationBox {
    margin-top: 16px;
    padding-left: 20px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    .redStar {
      line-height: 32px;
      padding-top: 5px;
      margin-right: 3px;
      color: #f56c6c;
    }
  }
}

.payBox {
  padding-top: 32px;
  padding-bottom: 28px;
  box-sizing: border-box;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  .title {
    font-weight: 600;
    font-size: 32px;
    color: #000000d9;
  }
  .subjectMatter,
  .payPrice {
    display: flex;
    justify-content: space-between;
    margin-top: 28px;
    font-weight: 400;
    font-size: 18px;
    color: #000000d9;
  }
}

.paySum {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  // align-items: flex-end;
  div {
    &:first-child {
      margin-top: 12px;
      font-weight: 400;
      font-size: 18px;
      color: #000000d9;
      margin-right: 40px;
    }
    &:last-child {
      font-size: 18px;
      color: #ff7f00;
      font-weight: 500;
      span {
        font-size: 32px;
      }
    }
  }
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
  .payBtn {
    width: 120px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #0dac65;
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
    cursor: pointer;
  }
}

.payPriceBox {
  .priceBox {
    padding-bottom: 33px;
    box-sizing: border-box;
    border-bottom: 1px solid #f0f0f0;
    .title {
      font-weight: 400;
      font-size: 14px;
      color: #000000d9;
      text-align: center;
    }
    .price {
      font-weight: 500;
      font-size: 24px;
      color: #000000d9;
      text-align: center;
      span {
        font-size: 32px;
        margin-left: 8px;
      }
    }
  }
  .amountAccounting {
    margin-top: 41px;
    padding-bottom: 32px;
    box-sizing: border-box;
    border-bottom: 1px solid #f0f0f0;
    .title {
      font-weight: 600;
      font-size: 16px;
      color: #000000d9;
    }
    .deposit {
      padding-left: 20px;
      margin-top: 24px;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      color: rgba(0, 0, 0, 0.6);
    }
    .realPay {
      margin-top: 20px;
      padding-left: 20px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .payPassword {
    margin-top: 32px;
    .payAccount {
      // display: flex;
      // justify-content: space-between;
      div {
        &:first-child {
          font-weight: 600;
          font-size: 16px;
          color: #000000d9;
        }
        &:last-child {
          text-align: right;
          margin-top: 10px;
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }
    .balance {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
      color: rgba(0, 0, 0, 0.6);
    }
  }
  .password {
    margin-top: 20px;
  }
}

.paySuccessBox {
  .payEnterprise,
  .personName,
  .bidNumber {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 16px;
  }
  .paySuccessBtn {
    display: flex;
    justify-content: flex-end;
    .paySuccess {
      width: 120px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #0dac65;
      font-weight: 400;
      font-size: 16px;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
.bdjs {
  min-height: 30vh;
}
/deep/.el-checkbox__label {
  font-weight: 600;
  font-size: 14px;
  color: #000000d9;
}

/deep/.el-dialog__title {
  font-weight: 600;
  font-size: 20px;
  color: #000000d9;
}
</style>
<style lang="less">
.bdjs {
  // text-indent: 2em; /* 2个空格的宽度 */
  // font-weight: 400;
  // font-size: 14px;
  // color: #000000d9;
  min-height: 50vh;
  padding-top: 20px;
  table,
  th,
  td {
    border: 1px solid #ccc;
    text-align: center;
    padding: 5px 0;
  }
  .firstRow {
    background: #f5f5f5;
    font-weight: 600;
  }

  .fileList {
    margin-top: 20px;
    span {
      cursor: pointer;
      color: #0dac65;
      font-size: 16px;
    }
  }
}
</style>